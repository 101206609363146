export * from "ce/components/EnvConfigSection";
import type { Props } from "ce/components/EnvConfigSection";
import type { DatasourceStorage } from "entities/Datasource";
import React from "react";
import type { EnvironmentType } from "ee/configs/types";
import { getEnvironments } from "ee/selectors/environmentSelectors";
import { useSelector } from "react-redux";
import { Text } from "@appsmith/ads";
import { capitalizeFirstLetter } from "utils/helpers";
import { renderDatasourceSection } from "pages/Editor/DataSourceEditor/DatasourceSection";

export function EnvConfigSection({
  config,
  currentEnv,
  datasource,
  viewMode,
}: Props) {
  const environments = useSelector(getEnvironments);
  const { datasourceStorages } = datasource;

  const checkIfStorageIsValid = (storage: DatasourceStorage | undefined) => {
    return (
      !!storage &&
      storage.hasOwnProperty("id") &&
      storage.hasOwnProperty("datasourceId") &&
      storage.hasOwnProperty("datasourceConfiguration")
    );
  };

  if (!environments || environments.length === 0) {
    return renderDatasourceSection(config, currentEnv, datasource, viewMode);
  }

  return (
    <>
      {environments.map((env: EnvironmentType) => {
        // check if key is present in the environments
        const envId = env.id;
        const storageValueForEnv = datasourceStorages.hasOwnProperty(envId)
          ? datasourceStorages[envId]
          : undefined;

        if (checkIfStorageIsValid(storageValueForEnv)) {
          return (
            <>
              <Text
                data-testid={`t--review-section-${env.name}`}
                kind={"heading-m"}
              >{`${capitalizeFirstLetter(env.name)} Environment`}</Text>
              {renderDatasourceSection(config, envId, datasource, viewMode)}
            </>
          );
        }
      })}
    </>
  );
}
